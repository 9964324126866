import classnames from 'classnames';
import React, { useContext } from 'react';

import { FooterCtaFragment } from '../../../../graphql.schema';
import { ModalRawHTML } from '../../RawHTML/ModalRawHTML';
import StrapiButton from '../../StrapiLink/StrapiButton';

import * as styles from './footer-cta.module.scss';
import ContactForm from '../../ContactForm';
import { PageContext } from '../../PageContext';

export const StrapiFooterCtaComponent: React.FC<FooterCtaFragment> = (props) => {
  const context = useContext(PageContext);

  return (
    <div
      id={`${props.element_id ?? 'footer-cta'}`}
      className={classnames('has-bg-brand-a inverted footer-cta-component-wrapper', styles.root)}
    >
      <div className="container">
        {props.title && <h2 className={classnames('title is-2', styles.title)}>{props.title}</h2>}
        {props.description && <p className={classnames('text-3', styles.description)}>{props.description}</p>}
        {props.link && <StrapiButton {...props.link} type="primary" />}
        {props.script && <ModalRawHTML html={props.script} />}
      </div>
      {context?.websiteLocale?.locale === 'he-IL' || context?.websiteLocale?.locale === 'ar-IL' ? <div className='container'>
        <ContactForm
          title={{ id: 'footer-details-title', defaultMessage: 'Contct Us' }}
          text={{ id: 'details-text', defaultMessage: 'Want to know how old you will be when you speak English at level 20? Leave Details' }}
          checkBox={{
            label: { id: 'contact-checkbox', defaultMessage: 'I agree to receive educational and/or marketing information via email or SMS' },
            name: 'sms', placeholder: { id: 'contact-sms', defaultMessage: 'sms' }, type: 'checkbox', required: false
          }}
          fullInputs={[
            { name: 'FullName', placeholder: { id: 'contact-full-name', defaultMessage: 'Fullname' }, type: 'text', required: true },
            { name: 'phone', placeholder: { id: 'contact-phone-placeholder', defaultMessage: 'Phone' }, type: 'text', pattern: '05[0-9]{8}', required: true },
          ]}
          submit={{ placeholder: { id: 'footer-send', defaultMessage: 'Send' } }}
          selectOptions={[]}
          inputs={[]}
          showFormInFooter
          className={styles.customFooter}
        />
      </div> : <></>}
    </div>
  );
};
