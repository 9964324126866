import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import minBy from 'lodash/minBy';

import StructuredData from '../StructuredData';
import { Maybe, SeoQuery } from '../../../graphql.schema';
import { PageContext } from '../PageContext';

type SEOProps = {
  title?: string;
  description?: Maybe<string>;
  image?: string;
  avoidIndexing?: boolean;
  canonicalURL?: string;
  keywords?: string;
  metaSocial?: {
    description?: string;
    socialNetwork?: string;
    title?: string;
    image?: {
      url: string;
    };
  }[];
  structuredData?: { [key: string]: string };
  alternatePages?: Array<{}>;
  currentPage?: {};
};

const SEO: React.FC<SEOProps> = (props) => {
  const metadata = useStaticQuery<SeoQuery>(query).site?.siteMetadata;
  const pageContext = useContext(PageContext)

  const metaFacebook = props.metaSocial?.find((s) => s.socialNetwork === 'Facebook');
  const metaTwitter = props.metaSocial?.find((s) => s.socialNetwork === 'Twitter');
  const defaultPath = props?.alternatePages?.find((item) => item?.locale === 'en')

  const seo = {
    title: props.title || metadata?.defaultTitle || '',
    description: props.description || metadata?.defaultDescription || '',
    image: props.image,
    canonicalURL: props.canonicalURL,
    keywords: props.keywords,
    structuredData: props.structuredData,
  };

  return (
    <>
      <Helmet title={seo.title}>
        <meta name="description" content={seo.description} />
        {seo.image && <meta name="image" content={seo.image} />}
        {props.avoidIndexing ? <meta name="robots" content="none" /> : null}
        {seo.keywords && <meta name="keywords" content={seo.keywords} />}
        {seo.canonicalURL && <link rel="canonical" href={seo.canonicalURL} />}

        {/* Open Graph: Facebook & LinkedIn */}
        {metaFacebook && <meta property="og:url" content={seo.canonicalURL} />}
        {metaFacebook?.title && <meta property="og:title" content={metaFacebook.title} />}
        {metaFacebook?.description && <meta property="og:description" content={metaFacebook.description} />}
        {metaFacebook?.image?.url && <meta property="og:image" content={metaFacebook?.image?.url} />}

        {/* Twitter card */}
        {metaTwitter?.title && <meta name="twitter:title" content={metaTwitter.title} />}
        {metaTwitter?.description && <meta name="twitter:description" content={metaTwitter.description} />}
        {metaTwitter?.image?.url && <meta name="twitter:image" content={metaTwitter?.image?.url} />}
        {metaTwitter && <meta name="twitter:card" content="summary_large_image" />}

        <html lang={pageContext?.websiteLocale?.locale} />

        {defaultPath ?
          <link
            key={`other-default-link`}
            data-testid="alternate-default-link"
            rel="alternate"
            hrefLang={'x-default'}
            href={`https://${defaultPath?.domainName}/${defaultPath?.isDefault ? defaultPath?.route?.split(/\/(.*)/s)[1] : defaultPath?.route || ''}`}
          /> :
          <link
            key={`path-default-link`}
            data-testid="site-default-link"
            rel="alternate"
            hrefLang={'x-default'}
            href={`${process.env.GATSBY_SITE_URL}${props.currentPage?.breadcrumb?.location ?? '/'}`}
          />
        }
        <link
          data-testid="alternate-link"
          rel="alternate"
          hrefLang={pageContext?.websiteLocale?.locale}
          href={`${process.env.GATSBY_SITE_URL}${props.currentPage?.breadcrumb?.location ?? '/'}`}
        />

        {props?.alternatePages?.length ?
          props?.alternatePages
          ?.filter(lang => !lang?.route?.startsWith("null"))
          ?.map((lang, index) => {
            if(!lang?.isTemplate && lang?.domainName && lang?.route){
            return (
              <link
                key={`${index}-alternate-link`}
                data-testid="alternate-link"
                rel="alternate"
                hrefLang={lang?.locale ?? 'x-default'}
                href={`https://${lang?.domainName}/${lang?.isDefault ? lang?.route?.split(/\/(.*)/s)[1] : lang?.route || ''}`}
              />
            )
          }
          }) : null}
      </Helmet>
      {seo.structuredData && <StructuredData structuredData={seo.structuredData} />}
    </>
  );
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
      }
    }
  }
`;
